import React from 'react';
import logo from '../../media/emark-logo-new.png';
import moment from 'moment'


const ContainerTop = (props) => {
  const { data } = props; 
  const address = data.address;

  var addressForHumans = '';

  if (address.street_name != null){
    addressForHumans = addressForHumans + address.street_name
  }

  if(address.street_no != null){
    addressForHumans = addressForHumans + ' ' + address.street_no
  }

  if(address.street_letter != null){
    addressForHumans = addressForHumans + address.street_letter
  }

  if(address.floor != null){
    addressForHumans = addressForHumans + ' ' + address.floor
  }
  if(address.side != null){
    addressForHumans = addressForHumans + ' ' + address.side
  }

  var human_created_at = moment(data.created_at).lang('da').format('DD-MM-YYYY')
  var human_start_date = moment(data.start_date).lang('da').format('DD-MM-YYYY')


  var binding_period_code = data.binding_period; 
  var binding_period = '';
  if(data.product.config.overwrite_binding_period === true){
    binding_period_code = data.product.config.binding_period
  } 

  switch (binding_period_code) {
    case null:
        binding_period = 'Ingen binding'
      break;
    case 'P1M':
        binding_period = '1 måned'
      break;
    case 'P3M':
        binding_period = '3 måneder'
      break;
    case 'P6M':
        binding_period = '6 måneder'
      break;
    case 'P12M':
        binding_period = '12 måneder'
      break;
    case 'P1Y':
        binding_period = '12 måneder'
      break;
    case 'P3Y':
        binding_period = '36 måneder'
      break;
    case 'P5Y':
        binding_period = '60 måneder'
      break;

    default:
        binding_period = '6 måneder'
      break;
  }

  if(data.product.config.hasOwnProperty('usage_limit')){
    var usage_limit_amount = data.product.config.usage_limit
  } else {
    var usage_limit_amount = 'does not exist'
  }

  var usage_limit = '';
  switch (usage_limit_amount) {
    case 6000:
      usage_limit = '6.000 kWh'
      break;
    
    case 10000:
      usage_limit = '10.000 kWh'
      break;

    default:
      usage_limit = 'Ingen misbrugsgrænse'
      break;
  }


  var terms = 'https://www.blueenergy.dk/privatbetingelser/'

  if(data.customer_type === 'private'){

    return (
      <div className="container top">
          <div className="col-lg-9 col-lg-push-3 col-sm-12">
          <p>Kære {data.name}</p>
          <p>Tillykke, du er nu tilmeldt hos Blue Energy. Vi tager os af alt omkring skiftet fra dit gamle elselskab.</p>
          <p> Link til vores salgs- og leveringsbetingelser finder du <a href={terms} target="_blank" rel="noopener noreferrer">her</a>.</p>
          <p></p>
          <form className="form-horizontal">
              <div className="well">
                  <h3>Dine oplysninger og betingelser:</h3>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Navn:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.name}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Addresse:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{addressForHumans}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">By:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{address.city} {address.zip_code}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">E-mail:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.email}</p></label>
                      </div>
                  </div>
                  <div className="form-group">  
                      <label className="col-sm-4 control-label">CPR-nummer:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.birthdate}-****</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Telefon:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.phone}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Aftale indgået:</label>
                    <div className="col-sm-7">
                          <label className="control-label disabled"><p>{human_created_at}</p></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Ikrafttrædelse:</label>
                    <div className="col-sm-7">
                          <label className="control-label disabled"><p>{human_start_date}</p></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Produkt:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled"><p>{data.product.config.name}</p></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Pris:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled">
                        <p className="value-price text-left">
                        { data.product.config.billing_type === 'flex'
                        ? <span>Variabel pris. Du kan se vores historiske priser på vores <a href="https://www.blueenergy.dk/prisoversigt">prisoversigt.</a></span>
                        : '0 øre/kWh inkl. moms' 
                      }
                           </p>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Binding:</label>
                    <div className="col-sm-7">
                        <label className="control-label disabled">
                            <p>{binding_period}</p>
                        </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Misbrugsgrænse:</label>
                    <div className="col-sm-7">
                        <label className="control-label disabled">
                            <p>{usage_limit}</p>
                        </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Abonnement:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled">
                        <p className="text-left">{data.product.config.price + ',00kr./md inkl. moms + transport og afgifter'}</p>
                      </label>
                    </div>
                  </div>
                  {data.product.config.oprettelse == 0 || data.product.config.oprettelse > 0 ? (<div className="form-group">
                    <label className="col-sm-4 control-label">Oprettelse:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled">
                        <p>{data.product.config.oprettelse + 'kr'}</p>
                      </label>
                    </div>
                  </div>) : ''}
              </div>
          </form>
      <p></p>
      <p>Velkommen til – vi glæder os til at skabe en mere klimavenlig verden sammen med dig!<br/></p>
      <p>Venlig hilsen,<br/>Blue Energy team</p>
      </div>
  
      <div className="col-lg-3 col-sm-6 col-lg-pull-9">
  
      
      <h3>Brug for hjælp</h3>
      <p>
          Kontakt os endelig. Vores kundeservice er åben mandag - torsdag mellem kl. 9.00 - 16.30 og fredag mellem 9.00 - 15.00.
          Ring på tlf. 70 28 40 00 eller skriv på <a href="mailto:kundeservice@blueenergy.dk">kundeservice@blueenergy.dk</a>
      </p>
      <a href="https://certifikat.emaerket.dk/eshop/blueenergy.dk" target="_blank" without rel="noopener noreferrer"><img src={logo} width="35%" style={{marginTop: '20px', marginBottom: '20px'}}/></a>
      <h3>Sikker handel</h3>
      <p>E-mærket er din garanti for, at vi lever op til gældende dansk lov samt en række særlige forbrugerbeskyttende krav. <a href="https://www.emaerket.dk/" target="_blank" without rel="noopener noreferrer">Læs mere her.</a></p>
  </div>
  </div>)
  }

  if (data.customer_type === 'business'){  

    terms = 'https://www.blueenergy.dk/erhvervsbetingelser/'
    return (
      <div className="container top">
          <div className="col-lg-9 col-lg-push-3 col-sm-12">
          <p>Kære {data.name}</p>
          <p>Tillykke, du er nu tilmeldt hos Blue Energy. Vi tager os af alt omkring skiftet fra dit gamle elselskab.</p>
          <p> Link til vores salgs- og leveringsbetingelser finder du <a href={terms} target="_blank" rel="noopener noreferrer">her</a>.</p>
          <p></p>
          <form className="form-horizontal" role="form">
              <div className="well">
                  <h3>Dine oplysninger og betingelser:</h3>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Navn:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.name}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Addresse:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{addressForHumans}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">By:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{address.city} {address.zip_code}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">E-mail:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.email}</p></label>
                      </div>
                  </div>
                  <div className="form-group">  
                      <label className="col-sm-4 control-label">CVR-nummer:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.cvr}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                      <label className="col-sm-4 control-label">Telefon:</label>
                      <div className="col-sm-7">
                          <label className="control-label disabled"><p>{data.phone}</p></label>
                      </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Aftale indgået:</label>
                    <div className="col-sm-7">
                          <label className="control-label disabled"><p>{human_created_at}</p></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Produkt:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled"><p>{data.product.config.name}</p></label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Pris:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled">
                        <p className="value-price text-left">
                        { data.product.config.billing_type === 'flex'
                        ? <span>Variabel pris. Du kan se vores historiske priser på vores <a href="https://www.blueenergy.dk/prisoversigt">prisoversigt.</a></span>
                        : '0 øre/kWh ekskl. moms' 
                      }
                           </p>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Binding:</label>
                    <div className="col-sm-7">
                        <label className="control-label disabled">
                            <p>{binding_period}</p>
                        </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Abonnement:</label>
                    <div className="col-sm-7">
                      <label className="control-label disabled"><p>
                      { data.product.config.billing_type === 'flex' ? (<p>
                                                        0 - 5000 kWh &nbsp;&nbsp;&nbsp;39 kr<br />
                            5001 - 10000 kWh &nbsp;&nbsp;&nbsp;79 kr<br />
                            10001 - 15000 kWh &nbsp;119 kr<br />
                            15001 - 20000 kWh &nbsp;159 kr<br />
                            &gt;20000 kWh &nbsp;259 kr
                                                    </p>) : 'Kr. ' + data.product.config.price + ',00/måned ekskl. moms + transport og afgifter'

                          }
                                                      </p></label>
                    </div>
                  </div>
              </div>
          </form>
      <p></p>
      <p>Velkommen til – vi glæder os til at skabe en mere klimavenlig verden sammen med dig!<br/></p>
      <p>Venlig hilsen,<br/>Blue Energy team</p>
      </div>
  
      <div className="col-lg-3 col-sm-6 col-lg-pull-9">
  
      
      <h3>Brug for hjælp</h3>
      <p>
          Kontakt os endelig. Vores kundeservice er åben mandag - torsdag mellem kl. 9.00 - 16.30 og fredag mellem 9.00 - 15.00.
          Ring på tlf. 70 28 40 00 eller skriv på <a href="mailto:kundeservice@blueenergy.dk">kundeservice@blueenergy.dk</a>
      </p>
      <a href="https://certifikat.emaerket.dk/eshop/blueenergy.dk" target="_blank" rel="noopener noreferrer"><img src={logo} width="35%" style={{marginTop: '20px', marginBottom: '20px'}}/></a>
      <h3>Sikker handel</h3>
      <p>E-mærket er din garanti for, at vi lever op til gældende dansk lov samt en række særlige forbrugerbeskyttende krav. <a href="https://www.emaerket.dk/" target="_blank" rel="noopener noreferrer">Læs mere her.</a></p>
  </div>
  </div>
  )
  }
  }

export default ContainerTop;