import React from 'react';

const CoverSignup = (props) => (
    <div className="cover-signup">
    <div style={{paddingTop: '200px'}}>
        <div className="boxheader">
            <h1>
                <span>{props.title}</span>
            </h1>
        </div>
    </div>
</div>
)

export default CoverSignup