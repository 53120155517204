import React from 'react';

const Footer = () => (
    <div className="footer">
    <div className="inner">
        <div className="footer-text">
            <span className="heavy">Blue Energy A/S</span>
            <br/><br/>
            Telefon: 70 28 40 00 <a href="mailto:kundeservice@blueenergy.dk">kundeservice@blueenergy.dk</a>
            <br/>
            Blue Energy A/S CVR: 34608784 
            <br/> 
            Købmagergade 22, 4. sal
            <br/>
            1150 København K
        </div>
        <div className="footer-text-small">Blue Energy er et 100% danskejet elselskab som leverer klimavenlig strøm fra nye danske vindmøller og solcelleanlæg. Med Blue Energy er du med til at støtte en grøn omstilling. Vi vil SPARE på og PRODUCERE klimavenlig strøm SAMMEN med vores kunder, med en økonomisk gevinst for alle ombord.</div>
    </div>
</div>
)

export default Footer