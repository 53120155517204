import React from 'react'
import { Header, CoverSignup, ContainerTop, Inner, Footer} from './components/index'
import axios from 'axios'


class Order extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false,
      data: {}
    }
  }


  fetchData(){

    let id = this.props.match.params.id;

    let url = `https://api-order.dwhmicroservices.com/prospect/${id}`;
    axios.get(url,  {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }})
    .then(response => this.setState({data: response.data.data, loaded: true}))
    .catch(error => window.location.href="https://blueenergy.dk/")

    /* TODO: Do something with errors.*/
  }

  componentDidMount(){
    this.fetchData()
  }
  render() {
    if(this.state.loaded){
      return (
        <div>
          <Header/>
          <CoverSignup title="Velkommen til Blue Energy"/>
          <ContainerTop data={this.state.data} terms=""/>
          <Inner />
          <Footer />
        </div>
      );
    }

    return <div></div>

    
  }
}

export default Order;
