/* eslint-disable no-useless-constructor */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Order from './Order';
import Confirm from './Confirm';
import Welcome from './Welcome';
class App extends React.Component {

    constructor(props){
        super(props)
    }
    
    render(){
        return (
        <Router>
            <Switch>
                <Route exact path="/ordre/:id" render={(props) => <Order {...props} />} />
                <Route exact path="/confirm/:id" render={(props) => <Confirm {...props} />} />
                <Route exact path="/welcome" render={(props) => <Welcome {...props} />} />
            </Switch>
        </Router>)
    }

}

export default App;