import React from 'react'
import { Header, CoverSignup, ContainerTop, Inner, Footer} from './components/index'
import Lottie from 'react-lottie';
import * as animationData from './confirm_load.json'


const Welcome = (props) => {
    const defaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: animationData.default
      };

    return (
        <div>

            <Header/>
            <CoverSignup title="Tillykke" />
            <div className="container top">
                <div className="col-lg-12 col-sm-12">
                  <div id="lottie-container">
                  <Lottie
                  options={defaultOptions}
                   height={200}
                  width={200}
                  eventListeners={[
                  {
                    eventName: 'complete',
                    callback: () => {
                      document.getElementById('lottie-container').remove()
                      document.getElementById('text').className = ''
                    }
                  }]}/>
                  </div>
                  <div  id="text" className="invisible">
                  <p>Du har nu accepteret tilbuddet. Vi vil hurtigst muligt sende dig en ordrebekræftelse, hvor du kan læse nærmere om aftalen.</p>
                  <p>Hvis du har nogen spørgsmål i mellemtiden er du altid mere end velkommen til at kontakte kundeservice.</p>
                  </div>
                    
                </div>
            </div>
            <Inner />
            <Footer />
        </div>)
}

export default Welcome