import React from 'react';
import logo from '../media/blueenergy-logo-web.png'

const Header = () => (
    <div className="header">
    <div className="header-container">
        <div className="logo"><a href="http://blueenergy.dk"><img src={logo} alt='Blueenergy Logo' width="197" height="35" /></a></div>
    </div>
</div>

)

export default Header;