import React from 'react'
import { Header, CoverSignup, ContainerTopConfirm, Inner, Footer} from './components/index'
import axios from 'axios'


class Confirm extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        loaded: false,
        data: {},
        loading_confirmation: false,
      }

      this.header = React.createRef();
      this.onSubmit = this.onSubmit.bind(this)
    }


    fetchData(){

        let id = this.props.match.params.id;
    
        let url = `https://api-order.dwhmicroservices.com/order/${id}`;
        axios.get(url,  {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }})
        .then(response => this.setState({data: response.data.data, loaded: true}))
        .catch(error => window.location.href="https://blueenergy.dk/")
    
        /* TODO: Do something with errors.*/
    }

    componentDidMount(){
      this.fetchData();
    }


    onSubmit = (event) => {
      event.preventDefault()

      this.setState({
        loaded: false
      })

      this.header.current.scrollIntoView({ behavior: 'smooth' })
      let id = this.props.match.params.id
      // TODO: URL 
      let url = `https://api-order.dwhmicroservices.com/order/${id}/confirm`

      let headers =  {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }}

      let data = {
      }
      axios.post(url, data, headers)
      .then(response => {
        window.location.href="/welcome"
      })
      .catch(error => {
          alert('Noget gik galt. Prøv igen!')
          this.setState({
            loaded: true
          })

      })

    }
    render() {

      return (
        <div ref={this.header}>
          <Header />
          <CoverSignup title="Tilbud fra Blue Energy"/>
          <ContainerTopConfirm loaded={this.state.loaded} onSubmit={this.onSubmit} data={this.state.data}/>
          <Inner />
          <Footer />
        </div>
        )
        
    }
}

export default Confirm;